import React from 'react';
import {
  ProGallery,
  GALLERY_CONSTS,
  addPresetStyles,
  addLayoutStyles,
} from 'pro-gallery';
import '../styles/Gallery.global.scss';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import '../styles/index';
import { utils } from '../utils/webUtils';
import EventHandler from './helpers/eventHandler';
import SiteHelper from './helpers/siteHelper';
import DimensionsHelper from './helpers/dimensionsHelper';
import FullscreenHelper from './helpers/fullscreenHelper';
import LogHelper from './helpers/logHelper';
import ItemsHelper from './helpers/itemsHelper';
import AccessibilityHelper from './helpers/accessibilityHelper';
import ProGalleryTestIdentifier from './ProGalleryTestIdentifier';

export default class CommonGalleryWrapper extends React.Component {
  constructor(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    super(props);
    this.avoidGallerySelfMeasure = true; // the wrapper is measuring for the gallery
    this.state = {
      fullscreen: {
        clickedIdx: props.clickedIdx >= 0 ? props.clickedIdx : -1,
        fullscreenAnimating: false,
        directFullscreenItem:
          !window.firstProGalleryRenderWithFullscreen &&
          props.directFullscreenItem,
      },
      isAccessible: false,
      itemsLoveData: {},
      container: {
        ...props.dimensions,
        avoidGallerySelfMeasure: this.avoidGallerySelfMeasure,
      },
      bannerHeight: 0,
    };
    const isStoreGallery = this.isStoreGallery();
    this.siteHelper = new SiteHelper(this, props, isStoreGallery);
    this.dimensionsHelper = new DimensionsHelper(this, props);
    this.logHelper = new LogHelper(
      this,
      props,
      this.getSentryDSN(),
      isStoreGallery,
    );
    this.itemsHelper = new ItemsHelper(this, props, isStoreGallery);
    this.fullscreenHelper = new FullscreenHelper(this, props, isStoreGallery);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.eventHandler = new EventHandler(this, props);

    this.onNewProps(props);

    this.generateApiIfNeeded();
    if (props.directFullscreenItem) {
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }
  // return true if art-store and false for pro-gallery
  isStoreGallery() {
    return false;
  }
  // fullscreen wrapper for OOI apps
  getFullscreenWrapperElement() {
    return null;
  }
  // sentry dsn for the app
  getSentryDSN() {
    return '';
  }
  // item resizer - with watermark for art-store
  getItemResizer() {
    return null;
  }
  // props that are passed for fullscreen wrapper
  getArtStoreProps() {
    return {};
  }
  // watermark for pro-gallery (if false no watermark will be send)
  getWatermark() {
    return false;
  }
  // get pro gallery element (artstore adding banner)
  getProGalleryElement(ProGalleryElement) {
    const wrappedProGalleryElement = this.getWrappedProGalleryIfNeeded(
      ProGalleryElement,
    );
    return wrappedProGalleryElement || ProGalleryElement;
  }

  getWrappedProGalleryIfNeeded(ProGalleryElement) {
    return false;
  }

  // end of common methods
  componentDidMount() {
    this.itemsHelper.initItemActions();
    this.accessibilityHelper.initAccessibility();
    this.dimensionsHelper.createResizeObserver();
    this.dimensionsHelper.createIntersectionObserver();

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    this.eventHandler.update(props);
    this.dimensionsHelper.update(props);
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.accessibilityHelper.update(props);
    !this.isStoreGallery() && this.setNewDirectFullscreenIfNeeded(props);
    this.viewMode = this.siteHelper.parseViewMode(props.viewMode);
    this.formFactor = this.siteHelper.parseFormFactor(props.formFactor);
  }

  setNewDirectFullscreenIfNeeded(props) {
    if (!props.directFullscreenItem) {
      return;
    }
    if (
      props.directFullscreenItem.itemId &&
      (!this.state.fullscreen.directFullscreenItem ||
        this.state.fullscreen.directFullscreenItem.itemId !==
          props.directFullscreenItem.itemId)
    ) {
      !window.firstProGalleryRenderWithFullscreen &&
        this.setState({
          fullscreen: {
            ...this.state.fullscreen,
            directFullscreenItem: props.directFullscreenItem,
          },
        });
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }

  generateApiIfNeeded() {
    try {
      if (utils.shouldDebug('albums_api') || utils.isInAlbumsBuilder()) {
        console.log('Generating API for Albums in window.AlbumsGalleryApi');
        window.AlbumsGalleryApi = {
          getItems: () => this.itemsHelper.pgItemsProps().items,
          setItems: (items) => {
            this.setState({ manualItems: items });
          },
          getOptions: () => this.siteHelper.getPGStyles(),
          setOptions: (styleParams) => {
            this.setState({ manualStyleParams: styleParams });
          },
        };
        window.addEventListener(
          'message',
          (event) => {
            try {
              console.log('Message arrived', event);
              const { method, data } = event.data;
              window.AlbumsGalleryApi[method](data);
            } catch (err) {
              console.log('Bad message arrived', err, event);
            }
          },
          false,
        );
      }
    } catch (e) {
      console.error('Could not create Manual Api', e);
    }
  }

  canRender(notInView, galleryId, pgStyles) {
    if (
      utils.isSSR() &&
      this.viewMode !== GALLERY_CONSTS.viewMode.SEO &&
      notInView
    ) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      // for this case, reportAppLoaded is already called in the viewerScript
      return false;
    }

    if (this.itemsHelper.areOneOrMoreItemsCorrupted(this.itemsProps.items)) {
      console.error('Gallery Wrapper, one or more items are corrupted');
      if (typeof this.props.sentryReport === 'function') {
        const error =
          'Gallery Wrapper, one or more items are corrupted. galleryId = ' +
          galleryId +
          ' items = ' +
          this.itemsProps.items;
        this.props.sentryReport(error);
      }
      this.logHelper.onAppLoaded();
      return false;
    }

    if (
      pgStyles.oneRow &&
      (this.state.container.height === undefined ||
        this.state.container.height <= 0)
    ) {
      // for this case, the height is 0 at the beginning, and will be set later.
      // so the appLoaded will be reported as usual, but in next renders
      return false;
    }

    return true;
  }

  render() {
    const props = { ...this.props.host, ...this.props }; // until props.host will be fully active sv_addPropsToHostInNativeComponent
    if (this.viewMode === GALLERY_CONSTS.viewMode.PREVIEW) {
      if (!props.propFromSetPropsIndicator) {
        // if this prop do not exist in the component (commonGalleryWrapper), it means that setProps was not called yet
        return null; // if setProps was not called yet, we don't want to render. For now in preview only
      }
    }
    const {
      queryParams,
      notInView,
      id,
      galleryId,
      forceHover,
      pageUrl,
    } = props;

    const { viewMode, formFactor } = this;

    const { container } = this.state;

    if (
      utils.isSSR() &&
      (experimentsWrapper.getExperimentBoolean('specs.pro-gallery.skipSsr') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }

    const pgStyles = this.siteHelper.getPGStyles();
    this.itemsProps = this.itemsHelper.pgItemsProps(pgStyles);

    if (this.itemsProps.items.length === 0) {
      this.eventHandler.setZeroItems();
    }

    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container,
      forceHover,
      noFollowForSEO: !this.siteHelper.isPremiumSite(),
      viewMode,
      formFactor,
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData, // can be removed cause the custom renderers are receiving it already
      resizeMediaUrl: this.getItemResizer(),
      pageUrl,
    };

    if (this.getWatermark()) {
      this.pgProps.watermark = this.getWatermark();
    }

    let dom = [];
    if (this.canRender(notInView, galleryId, pgStyles)) {
      if (
        utils.isSSR() &&
        experimentsWrapper.getExperimentBoolean(
          'specs.pro-gallery.useCustomElement',
        )
      ) {
        dom.push(
          <wix-pro-gallery
            data-parent-id={'gallery-wrapper-' + id}
            data-items={JSON.stringify(
              this.itemsProps.items.slice(0, 25).map((item) => ({
                id: item.itemId,
                width: item.metaData.width,
                height: item.metaData.height,
              })),
            )}
            data-options={JSON.stringify(
              addLayoutStyles(addPresetStyles(pgStyles)),
            )}
          />,
        );
      }
      dom.push(
        <ProGalleryTestIdentifier testType={this.props.testType} />,
        this.getProGalleryElement(
          <ProGallery
            key="pro-gallery"
            ref={(node) => (this.node = node)}
            styles={pgStyles}
            eventsListener={this.eventHandler.handleEvent}
            {...this.pgProps}
            {...this.itemsProps}
            {...this.itemsHelper.getExternalInfoRenderersIfNeeded()}
          />,
        ),
      );
      const ProFullscreenWrapper = this.getFullscreenElement();
      dom.push(
        <ProFullscreenWrapper
          {...this.pgProps}
          {...this.getArtStoreProps()}
          {...this.fullscreenHelper.fullscreenItemsProps()}
          key="pro-fullscreen"
          styleParams={this.siteHelper.getFullscreenStyles()}
          scrollTo={this.props.scrollTo}
          fullscreenAnimating={this.state.fullscreen.fullscreenAnimating}
          fullscreenIdx={
            this.state.fullscreen.directFullscreenItem &&
            this.state.fullscreen.directFullscreenItem.itemId
              ? 0
              : this.state.fullscreen.clickedIdx
          }
          eventsListener={this.eventHandler.handleFullscreenEvent}
        />,
      );
    } else {
      dom = null;
    }
    const responsiveGallery =
      (pgStyles.oneRow ||
        (!pgStyles.oneRow &&
          pgStyles.enableInfiniteScroll === false &&
          !this.loadMoreClicked)) &&
      !(this.props.host.dimensions.height > 0);
    const dangerouslySetInnerHTMLObject = responsiveGallery
      ? {
          __html: `div.${id} {
        height: 100%;
        width: 100%;
        position: relative;
      }
      div.${id} > div {
        position: absolute;
        top: 0;
        left: 0;
      }`,
        }
      : {
          __html: `div.${id} {
      height: 100%;
      width: 100%;
      }`,
        };
    return (
      <div
        id={`gallery-wrapper-${id}`}
        key={`gallery-wrapper-${id}`}
        style={{ overflow: 'hidden', height: `100%`, width: '100%' }}
      >
        <style dangerouslySetInnerHTML={dangerouslySetInnerHTMLObject} />
        {dom}
      </div>
    );
  }
}
