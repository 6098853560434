import React from 'react';
import { ProGallery } from 'pro-gallery';
import '../../../styles/Gallery.global.scss';

export default class FullscreenAsProGallery extends React.Component {
  styleParams = {
    gotStyleParams: true,
    selectedLayout: 0,
    isRTL: false,
    isVertical: false,
    gallerySize: 45,
    minItemSize: 120,
    groupSize: 3,
    chooseBestGroup: true,
    groupTypes: '1,2h,2v,3t,3b,3l,3r',
    cubeImages: false,
    cubeType: 'fit',
    smartCrop: false,
    fullscreen: true,
    allowSocial: true,
    allowDownload: true,
    allowTitle: true,
    allowDescription: true,
    loveButton: true,
    loveCounter: false,
    videoLoop: true,
    videoSound: false,
    videoSpeed: 1,
    videoPlay: 'auto',
    gallerySliderImageRatio: 1.3333333333333333,
    galleryImageRatio: 2,
    numberOfImagesPerRow: 3,
    sharpParams: { quality: 90, usm: {} },
    collageAmount: 0.8,
    collageDensity: 1,
    imageMargin: 10,
    galleryMargin: 0,
    floatingImages: 0,
    viewMode: 'preview',
    galleryHorizontalAlign: 'center',
    galleryTextAlign: 'center',
    galleryVerticalAlign: 'center',
    enableInfiniteScroll: 1,
    itemClick: 'link',
    cubeRatio: 1,
    fixedColumns: 0,
    oneRow: false,
    showArrows: false,
    isSlideshow: false,
    hasThumbnails: false,
    galleryThumbnailsAlignment: 'bottom',
    thumbnailSpacings: 5,
    gridStyle: 1,
    titlePlacement: 'SHOW_ON_HOVER',
    hoveringBehaviour: 'APPEARS',
    mobilePanorama: false,
    isAutoSlideshow: false,
    slideshowLoop: false,
    playButtonForAutoSlideShow: false,
    allowSlideshowCounter: false,
    autoSlideshowInterval: 4,
    arrowsSize: 23,
    slideshowInfoSize: 200,
    imageLoadingMode: 'BLUR',
    scrollAnimation: 'NO_EFFECT',
    overlayAnimation: 'NO_EFFECT',
    imageHoverAnimation: 'NO_EFFECT',
    expandAnimation: 'NO_EFFECT',
    itemBorderWidth: 0,
    itemBorderColor: { themeName: 'color_15', value: 'rgba(0,0,0,1)' },
    itemBorderRadius: 0,
    itemEnableShadow: false,
    itemShadowOpacityAndColor: {
      themeName: 'color_15',
      value: 'rgba(0,0,0,0.2)',
    },
    itemShadowBlur: 20,
    loadMoreAmount: 'all',
    itemShadowDirection: 135,
    itemShadowSize: 10,
    imageInfoType: 'NO_BACKGROUND',
    textBoxBorderRadius: 0,
    textBoxBorderWidth: 0,
    textBoxBorderColor: { themeName: 'color_15', value: 'rgba(0,0,0,1)' },
    titleDescriptionSpace: 6,
    textsVerticalPadding: 0,
    textsHorizontalPadding: 0,
    textBoxFillColor: {
      themeName: 'color_12',
      value: 'rgba(230,230,230,1)',
    },
    textBoxHeight: 200,
    textImageSpace: 10,
    alwaysShowHover: false,
    isStoreGallery: false,
    previewHover: false,
    calculateTextBoxHeightMode: 'AUTOMATIC',
    galleryLayout: 13,
    thumbnailSize: 120,
    useCustomButton: false,
    itemOpacity: {
      themeName: 'color_14',
      value: 'rgba(155,155,155,0.6)',
    },
    itemFont: {
      style: { bold: true, italic: false, underline: false },
      family: 'open sans',
      preset: 'Heading-M',
      editorKey: 'font_5',
      size: 22,
      fontStyleParam: true,
      displayName: 'Basic Heading',
      value: 'font:normal normal bold 22px/1.4em "open sans",sans-serif;',
    },
    itemFontSlideshow: {
      family: 'avenir-lt-w01_85-heavy1475544',
      displayName: 'Basic Heading',
      style: { bold: false, italic: false, underline: false },
      size: 22,
      preset: 'Custom',
      editorKey: 'font_5',
      fontStyleParam: true,
      value:
        'font:normal normal normal 22px/27px avenir-lt-w01_85-heavy1475544,sans-serif;',
    },
    itemDescriptionFontSlideshow: {
      family: 'avenir-lt-w01_35-light1475496',
      displayName: 'Paragraph 2',
      style: { bold: false, italic: false, underline: false },
      size: 15,
      preset: 'Custom',
      editorKey: 'font_8',
      fontStyleParam: true,
      value:
        'font:normal normal normal 15px/18px avenir-lt-w01_35-light1475496,sans-serif;',
    },
    itemDescriptionFont: {
      family: 'avenir-lt-w01_35-light1475496',
      displayName: 'Paragraph 2',
      style: { bold: false, italic: false, underline: false },
      size: 15,
      preset: 'Custom',
      editorKey: 'font_8',
      fontStyleParam: true,
      value:
        'font:normal normal normal 15px/18px avenir-lt-w01_35-light1475496,sans-serif;',
    },
    itemFontColor: {
      themeName: 'color_11',
      value: 'rgba(255,255,255,1)',
    },
    itemFontColorSlideshow: {
      themeName: 'color_15',
      value: 'rgba(0,0,0,1)',
    },
    itemDescriptionFontColor: {
      themeName: 'color_11',
      value: 'rgba(255,255,255,1)',
    },
    itemDescriptionFontColorSlideshow: {
      themeName: 'color_15',
      value: 'rgba(0,0,0,1)',
    },
    loadMoreButtonText: 'Load More',
    loadMoreButtonFont: {
      family: 'open sans',
      displayName: 'Paragraph 2',
      style: { bold: false, italic: false, underline: false },
      size: 15,
      preset: 'Body-M',
      editorKey: 'font_8',
      fontStyleParam: true,
      value: 'font:normal normal normal 15px/1.4em "open sans",sans-serif;',
    },
    loadMoreButtonFontColor: {
      themeName: 'color_15',
      value: 'rgba(0,0,0,1)',
    },
    loadMoreButtonColor: {
      themeName: 'color_11',
      value: 'rgba(255,255,255,1)',
    },
    loadMoreButtonBorderColor: {
      themeName: 'color_15',
      value: 'rgba(0,0,0,1)',
    },
    arrowsColor: { themeName: 'color_11', value: 'rgba(255,255,255,1)' },
    oneColorAnimationColor: {
      themeName: 'color_11',
      value: 'rgba(255,255,255,1)',
    },
    isAccessible: false,
  };
  render() {
    const { styleParams } = this;
    return <ProGallery styles={styleParams} {...this.props} />;
  }
}
